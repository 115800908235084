import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import TICKER from '../components/todays-ticker'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import People from "@mui/icons-material/People";



const BlogTemp = ({ pageContext, location }) => (
    <Layout>
        <SEO 
        pagetitle="TikTokフォロワー増加率ランキング(5日)"
        pagedesc="TikTokフォロワー増加率(5日) / Percentage increase in followers (5 days)"
        pagepath={location.pathname}
        />
        <div className="main">
        {/* <TICKER nodes={c.allTiktokAccountMst.nodes}></TICKER> */}
        <div class ="Sy70mc">
        
        <div class="LTwfK"><span class="LzRWEc"><People/>フォロワー数増加率(5日)</span><span class="MzhJl">Percentage increase in followers (5 days)</span></div>
        <TICKER nodes={pageContext.post}></TICKER>
        <ul className="pagenation">

          {!pageContext.isFirst && (
            <li className="prev">
                <Link
                to={
                  pageContext.currentPage === 2
                    ? `/tiktok-follower-5/`
                    : `/tiktok-follower-5/${pageContext.currentPage - 1}/`
                }
                rel="prev"
              >
                <FontAwesomeIcon icon={faChevronLeft} size="lg"/>
              </Link>
            </li>
          )}
          {!pageContext.isLast && (
            <li className="next">
              <Link to={`/tiktok-follower-5/${pageContext.currentPage + 1}/`} rel="next">
                <FontAwesomeIcon icon={faChevronRight} size="lg"/>
              </Link>
            </li>
          )}
        </ul>
        </div>
        </div>
    </Layout>
)


export default BlogTemp
