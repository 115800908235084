import React from "react"
import { GatsbyImage, getImage, StaticImage  } from 'gatsby-plugin-image'
import DeltaRatio from './delta-ratio'
import People from "@mui/icons-material/People";

export default ({nodes}) => {
    // let fileId = "1FzOAq-_aHdTTueF6u5M6OgvVCG7g8JxS";
    return (
    <ul class="wR6Hc Bb3Pm">
    {nodes.map(node => (
        <li>
        <a href={"/" + node.domain +"/"+node.unique_id}>
        <div>
            <div class="aV7dr p7JuY">
            <div class="P3hHa">
                <div class="Z6rvz Ma5yY">
                <div class="Yq2bQ Aq25Y">
                {/* <StaticImage  src={node.avater_url} width={45} height={45} alt=""/> */}
                <GatsbyImage image={getImage(node.avaterImage)} alt={node.unique_id}  width={40} height={40}/>
                </div>
                
                </div>
                <div class="p4HfS">{node.account_name}</div>
                <div class="Er6VT V7kPK">
                <div class="s3HXV">
                    <div class="Y9fAg"><span class="A2anm">{node.character_words}</span>
                    </div>
                </div>
                </div>
            </div>
            
            {/* <span class="A2anm">三菱UFJフィナンシャル・グループ</span> */}
            
            <div class="c2YAC fF3hC Ma5yY">
                <span class="e9DyQ">
                    <div class="U4b3M">
                        <div class="Gg2Li"><People/>{node.latestValues.follower_count.toLocaleString()}</div>
                    </div>
                </span>
            </div>
            <div class="c2YAC r5QAX Ma5yY">
                <DeltaRatio deltaRatio = {node.deltaRatioValues._5.follower_count}></DeltaRatio>
            </div>
        </div>
        <div class="K7vtB">
            <div class="tU5NW"><span class="A2anm">{node.character_words}</span></div>
        </div>
        </div>
        </a>
        </li>
    ))}
    </ul>
);

}